import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const OnboardingModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const modalDismissed = localStorage.getItem('onboardingDismissed');
    if (!modalDismissed) {
      setShowModal(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('onboardingDismissed', 'true');
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Welcome to Squash Finder - London</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Easily find public squash court bookings near you.</p>
        <ul>
          <li>Search for a court at your local centre</li>
          <li>See availability and click through to the booking site</li>
          <li>If there are no courts at your chosen time, see other options nearby</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Do not show again
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardingModal;
