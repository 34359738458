// import './App.css';
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Finder from './components/Finder';
import OnboardingModal from './components/OnboardingModal';

function App() {
  const availabilityEndpoint = `${process.env.REACT_APP_BACKEND_HOST}/api`;
  return (
    <Container fluid>
      <OnboardingModal/>
      <Row>
        <Col>
          <h2>Squash Court Finder</h2>
          <h5>London</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Finder availabilityEndpoint={availabilityEndpoint}/>
        </Col>
      </Row>
    </Container>

  );
}

export default App;
