import React, {useState, useEffect} from "react";
import { Locations, bookingLink, getTransportLogoPath, getTransportLogoAltText, getMapsLink } from "../utils/LocationsList";
import { slotComparator } from "../utils/Formatting";
import FinderResultsView from "./FinderResultsView";
import { ApiReponseError, ApiResponseLocation, CourtSlot } from "../utils/GetAvailabilities";
import { findClosestTimeSlots, slotIsAvailable } from "../utils/Logic";
import { Row, Card, Stack} from "react-bootstrap";
import GetPricing from "../utils/GetPricing";

type FinderResponseProps = {
    baseUrl: string,
    location: string,
    date: string,
    time: string,
    response: ApiResponseLocation | undefined,
    isAlternative: boolean
}

type NearestSlots = {
    closest: CourtSlot | null,
    previous: CourtSlot | null,
    next: CourtSlot | null
}

const noSlotsError = "No slots available for this date and location";

function FinderResponse({baseUrl, location, date, time, response, isAlternative}: FinderResponseProps) {
    const [slots, setSlots] = useState<NearestSlots>({closest: null, previous: null, next: null});
    const [responseErrors, setResponseErrors] = useState<ApiReponseError[]>([]);
    const [bookLink, setBookLink] = useState('');
    const [requestError, setRequestError] = useState<string|null>(null);

    const handleAvailableSlots = async (availableSlots: CourtSlot[]) => {
        const sorted = availableSlots.sort(slotComparator);
        const newSlots = findClosestTimeSlots(sorted, time);
        const slotsWithPricing = await handleSlotPricing(newSlots);
        setSlots(slotsWithPricing);
        setResponseErrors([]);
        setBookLink(prev => bookingLink(location, date) ?? prev);
    };
    
    const handleNoSlotsAvailable = () => {
        console.debug("No Slots");
        setRequestError(noSlotsError);
    };
    
    const handleResponseErrors = () => {
        if (response?.errors) {
            console.debug(JSON.stringify(response.errors));
            setResponseErrors(response.errors);
        } 
    };

    const handleSlotPricing = async (slots: NearestSlots) => {
        const locationsToUpdate = ["Balham", "Tooting", "Tolworth"];

        if (!locationsToUpdate.includes(location)) {
            console.log(`Location ${location} is not in the list of locations to update.`);
            return slots;
        }
    
        const getNewPrice = async (slot: CourtSlot | null) => {
            if (slot && locationsToUpdate.includes(location) && slot.price === 0) {
                try {
                    const newPrice = await GetPricing(baseUrl, location, date, slot);
                    return { ...slot, price: newPrice };
                } catch (error) {
                    console.error("Failed to get new price:", error);
                    return slot; // Return the original slot if there's an error
                }
            }
            return slot; // Return the original slot if no update is needed
        };
    
        console.log(`location is ${location}`);
    
        const [updatedClosest, updatedPrevious, updatedNext] = await Promise.all([
            getNewPrice(slots.closest),
            getNewPrice(slots.previous),
            getNewPrice(slots.next)
        ]);

        return { closest: updatedClosest, previous: updatedPrevious, next: updatedNext };
    };

    useEffect(() => {
        if (response && response.dates && response.dates[0]) {
            const availableSlots = response.dates[0].slots.filter((s: CourtSlot) => slotIsAvailable(s));
            if (availableSlots.length > 0) {
                handleAvailableSlots(availableSlots);
            } else {
                handleNoSlotsAvailable();
            }
        } else if (response && response.errors) {
            console.log('response errors')
            handleResponseErrors();
        }
    }, [response]);

    const resultsView = () => <FinderResultsView 
        closest={slots.closest} 
        before={slots.previous} 
        after={slots.next} 
        errors={responseErrors} 
        link={bookLink} 
        isAlternative={isAlternative}/>

    return (<>

                {!isAlternative && 
                    <Row>
                        {!requestError && resultsView()}
                        {requestError && <div className="p-1">{requestError}</div>}
                    </Row>
                }
                {isAlternative && <Card border="light">
                    <Card.Body>
                        <Card.Text style={{margin: 0}}>{Locations[location].displayName}</Card.Text>
                        <Stack direction="horizontal" style={{margin:6}}>
                            <Stack direction="horizontal" style={{margin:4}}>
                                <img src={getTransportLogoPath(location)} width={14} height={12} style={{margin:4}} alt={getTransportLogoAltText(location)}/>
                                <Card.Text style={{fontSize: "12px"}}>{Locations[location].transport.station}</Card.Text>
                            </Stack>
                            <Card.Link href={getMapsLink(location)} target="_blank" style={{fontSize: "14px"}}>Map</Card.Link>
                        </Stack>
                        {!requestError && resultsView()}
                        {requestError && <div className="p-1">{requestError}</div>}
                    </Card.Body>
                </Card>}
    </> 
    )
}

export default FinderResponse;