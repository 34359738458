import { Locations } from "./LocationsList";

export type ApiResult = ApiResponseLocation | undefined

export type ApiResponseProvider = {
    provider: string
    locations: ApiResponseLocation[]
}

export type ApiResponseLocation = {
    location: string,
    dates: ApiResponseDate[]
    errors: ApiReponseError[]
}

export type ApiReponseError = {
    date: string,
    error: string
}

export type ApiResponseDate = {
    date: string,
    slots: CourtSlot[]
}

export type CourtSlot = {
    startTime: string,
    endTime: string,
    price: number,
    availability: number | null
}

async function GetAvailabilities(baseUrl: string, start: string, end: string, searchLocations: string[]): Promise<[ApiResponseLocation[], string | null]> {
    const availabilityEndpoint = '/availability';
    const urlToFetch = baseUrl + availabilityEndpoint;

    console.log('Preparing request:' + start + ' '+ end);

    const locations = {
        places: searchLocations.filter(l => Locations[l].provider === 'places'),
        better: searchLocations.filter(l => Locations[l].provider === 'better'),
        activeLambeth: searchLocations.filter(l => Locations[l].provider === 'activeLambeth'),
        everyoneActive: searchLocations.filter(l => Locations[l].provider === 'everyoneActive')
    }

    console.log('locations: ' + JSON.stringify(locations));

    const body = {
        startDate: start,
        endDate: end,
        locations: locations
    };

    console.log('body: ' + JSON.stringify(body));

    const formatResponse = (apiResponse: ApiResponseProvider[]) => { 
        const flatResponse = apiResponse.map((prov) => prov.locations).flat();
        return searchLocations.map(site => flatResponse.find((loc) => loc.location === site)) as ApiResponseLocation[]
    }
        

    try {
        const response = await fetch(
            urlToFetch, 
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                },
            }
            );
    
        if (response.ok) {
            const responseJson = await response.json();
            return [formatResponse(responseJson), null]
        } else {
            console.log(`Request failed: ${response.status}`)
            const requestError = `Request failed. Try again later. Error: ${response.status}`;
            return [[], requestError]
        }
    } catch(error: any) {
        console.error(`Network error: ${error.message}`);
        const requestError = `Request failed. Try again later. Error: ${error.message}`
        return [[], requestError];
    }

}

export default GetAvailabilities;