import React from "react";
import { Row, Col, Form, Button, Spinner, Container } from "react-bootstrap";
import { DateTime } from "luxon";
import { locationsByProvider, providerDisplayNames } from "../utils/LocationsList";
import { formatDate } from "../utils/Formatting";

type FinderSearchProps = {
    currentTime: string,
    onSelectDate: (selected: string) => void,
    onSelectTime: (selected: string) => void,
    onSelectLocation: (selected: HTMLCollectionOf<HTMLOptionElement>) => void,
    onSearch: () => void,
    isLoading: boolean
}

function FinderSearch({currentTime, onSelectDate, onSelectTime, onSelectLocation, onSearch, isLoading}: FinderSearchProps) {

    const timeOptions = Array.from({length: 33}, (_,i) => `${String(Math.floor(i/2) + 7).padStart(2, '0')}:${(i%2)*30}`.padEnd(5, '0'));
    const dateOptions = Array.from({length: 10}, (_, i) => DateTime.now().plus({days: i}));

    const locationOptions = () => {
        return Object.keys(locationsByProvider).map(provider => (
            <optgroup key={provider} label={providerDisplayNames[provider]}>
                {locationsByProvider[provider].map(([locationName, location]) => (
                    <option key={locationName} value={locationName}>
                        {location.displayName}
                    </option>
                ))}
            </optgroup>
        ));
    }

    return (
        <Container style={{ marginLeft: 0}} >
            <Row>
                <Col>
                <Form>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label>Date</Form.Label>
                        <Form.Select onChange={({target}) => {onSelectDate(target.value)}}>
                            {dateOptions.map((d) => <option key={d.toLocaleString()} value={d.toISODate() as string}>{formatDate(d)}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label>Time</Form.Label>
                        <Form.Select onChange={({target}) => {onSelectTime(target.value)}}>
                            {timeOptions.map((t) => <option key={t} value={t} selected={t === currentTime}>{t}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label>Location</Form.Label>
                        <Form.Select onChange={({target}) => {onSelectLocation(target.selectedOptions)}}>
                            {locationOptions()}
                        </Form.Select>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            <Row style={{marginTop: 10, marginBottom: 10}}>
                <Col>
                <Button variant="primary" onClick={onSearch} >
                    {isLoading && <>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        <span>Loading...</span>
                    </>
                    }
                    {
                      !isLoading && <span>Search</span>
                    }
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default FinderSearch;